import { AnimatePresence, motion } from "framer-motion";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState, useRef } from "react";

import Footer from "../../components/footer";
import Header from "../../components/header";
import NavMenu from "../../components/nav-menu";
import { messages } from "../../i18n-translations";

import brazingTablet from "../../images/brazing-tablet.png";
import brazing from "../../images/brazing.png";

const ResourcesPage: React.FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navTopMargin, setNavTopMargin] = React.useState(40);
  const header = useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const isScreenLg = breakpoints.lg;
  const isScreenMd = breakpoints.md;

  useEffect(() => {
    if (header.current) setNavTopMargin(header.current.clientHeight);
  }, [header]);

  const currentYear = new Date().getFullYear().toString();

  return (
    <main className="relative z-0 w-screen bg-white">
      <div ref={header} className="fixed top-0 w-full bg-white z-110">
        <Header
          isNavOpen={isNavOpen}
          onMenuButtonPress={() => setIsNavOpen(!isNavOpen)}
          cn
        />
      </div>
      {
        //#region Mobile Nav
      }
      {!isScreenXl && (
        <AnimatePresence>
          {isNavOpen && (
            <div
              className={`fixed flex inset-0 z-101 w-screen h-screen bg-transparent`}
            >
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="flex w-full origin-top"
                key="nav"
                style={{ marginTop: navTopMargin }}
              >
                <NavMenu cn />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      )}
      {
        //#endregion
      }

      {
        //#region Header Image
      }
      <div style={{ marginTop: navTopMargin }} className="bg-white">
        <div className="relative">
          <div className="z-0 w-full h-full">
            {isScreenLg ? (
              <StaticImage
                src={"../../images/resources-header.webp"}
                alt="About Us"
                layout="fullWidth"
              />
            ) : (
              <StaticImage
                src={"../../images/resources-header-md.webp"}
                alt="About Us"
                layout="fullWidth"
              />
            )}
          </div>
          <div className="absolute inset-0 z-0 flex flex-col items-center justify-center text-white bg-black opacity-80">
            <h1 className="text-5xl">{messages.cn.resources}</h1>
          </div>
        </div>
      </div>

      {
        //#endregion
      }

      <div className="px-4 py-10 mx-auto sm:px-10 lg:px-20 xl:container">
        <div className="flex flex-col items-center w-full">
          <>
            <div className="flex flex-col items-center p-6 text-center md:items-start md:text-left lg:w-3/4">
              <div className="relative md:w-full">
                <div className="absolute top-0 left-0 z-10 flex flex-col justify-between w-2/3 h-full px-6 py-8 text-left text-white bg-black md:w-5/12 bg-opacity-80 md:text-lg">
                  <div>
                    {messages.cn.ningboJintai}
                    <br />
                    {messages.cn.rubberAndPlasticCo}
                  </div>
                  <div className="text-3xl leading-10 md:text-4xl md:leading-tight">
                    {currentYear}
                  </div>
                </div>
                <img
                  src={isScreenMd ? brazingTablet : brazing}
                  alt=""
                  className="w-full h-auto"
                />
              </div>

              <div className="flex flex-col items-center justify-center w-full pt-8 md:flex-row">
                <div
                  id="companyProfile"
                  className="flex flex-col items-center justify-center w-1/2 py-4 cursor-pointer md:flex-row md:pb-10"
                >
                  <StaticImage
                    src="../../images/pdf-icon.png"
                    alt=""
                    className="w-1/4 md:w-14"
                  />
                  <div className="md:flex md:flex-col md:justify-center md:ml-3">
                    <div className="py-3 leading-tight text-blue-600 md:py-0 md:text-lg">
                      {messages.cn.downloadCompanyProfile}
                    </div>
                    <div>PDF, 1MB</div>
                  </div>
                </div>
                <div
                  className="flex flex-col items-center justify-center w-1/2 py-4 cursor-pointer md:flex-row md:pb-10"
                  id="materialList"
                >
                  <StaticImage
                    src="../../images/pdf-icon.png"
                    alt=""
                    className="w-1/4 md:w-14"
                  />
                  <div className="md:flex md:flex-col md:justify-center md:ml-3">
                    <div className="py-3 leading-tight text-blue-600 md:py-0 md:text-lg">
                      {messages.cn.downloadMaterialList}
                    </div>
                    <div>PDF, 1MB</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      <Footer cn />
    </main>
  );
};

export default ResourcesPage;
